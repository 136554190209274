import React, { Fragment, useState } from 'react'
import "./App.css"
import { Route, Routes } from 'react-router-dom'
import {Header} from './Components/Navbar_/Navbar_'
import MainPage from './Components/MainPage/MainPage'
import backgroundImg from "./img/Vectorbacground.svg"
import backgroundImg2 from "./img/Vector 1.png"
import FastFood from './Components/FastFood/FastFood'
import PublicFood from './Components/PublicFood/PublicFood'
import MobileApp from './Components/MobileApp/MobileApp'
import Retail from './Components/Retail/Retail'
import EntertainmentCenters from './Components/EntertainmentCenters/EntertainmentCenters'
import Fitness from './Components/Fitness/Fitness'
import Hotels from './Components/Hotels/Hotels'
import Cinema from './Components/Cinema/Cinema'
import Hardware from './Components/Hardware/Hardware'
import Popup from './Components/Popup/Popup'
import Printers from './Components/products/Printers'
import CashStations from './Components/products/CashStations'
import RetailStoreEquipment from './Components/products/RetailStoreEquipment'
import {FooterWithSocialLinks} from "./Components/FooterWithSocialLinks/FooterWithSocialLinks";


function Layout({lang,setLang}) {
  const [isTrue,setIsTrue]=useState(false)

  return (
    <Fragment >
        <Header lang={lang} setLang={setLang}/>
        <img alt="bcg" className="BackImg" src={backgroundImg}/>
        <img alt="bcg" className="BackImgmobile" src={backgroundImg2}/>
       <main>
        <Routes>
              <Route path='/' element={<MainPage lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='FastFood' element={<FastFood lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='PublicFood' element={<PublicFood lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='MobileApp' element={<MobileApp lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Retail' element={<Retail lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='EntertainmentCenters' element={<EntertainmentCenters lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Fitness' element={<Fitness lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Hotels' element={<Hotels lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Cinema' element={<Cinema lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware' element={<Hardware lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware/Printers' element={<Printers lang={lang} setIsTrue={setIsTrue}/>}/>
              <Route path='Hardware/CashStations' element={<CashStations lang={lang}/>}/>
              <Route path='Hardware/Retail_store_equipment' element={<RetailStoreEquipment lang={lang}/>}/>
        </Routes>
       </main>
       <Popup isTrue={isTrue} setIsTrue={setIsTrue} lang={lang} />
       <FooterWithSocialLinks lang={lang} />
    </Fragment>
  )
}

export default Layout
