import headerPic from "../../img/MobileApp/headerpic1.png";
import headerPic2 from "../../img/MobileApp/headerpic2.png";
import Button from "../Button/Button";

import bacground from "../../img/vector.png";
import rotatedPhone from "../../img/MobileApp/rotatedphone.png";
import Phone1 from "../../img/MobileApp/phone1.png";
import RESTA1 from "../../img/MobileApp/Resta_1.webp";
import Phone2 from "../../img/MobileApp/phone2.png";
import Phone3 from "../../img/MobileApp/phone3.png";

import "./MobileApp.css"
import SlideLine from "../SlideLine/SlideLine";
import {useTranslation} from "react-i18next";

function MobileApp({lang, setIsTrue}) {
    const { t } = useTranslation();
    return (
     <div>
       {/* header */}
       <div className="PublicFood_header">
        <div className="PublicFood_header_text">
          <h1>
            {t("MobileApp_title_part1")} <span> RestA </span> {t("MobileApp_title_part2")}
          </h1>
        </div>
        <div className="PublicFood_header_picture">
          <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
          <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="PublicFood_button_component">
            <Button lang={lang} setIsTrue={setIsTrue}/>
          </div>
        </div>
      </div>
      {/* block 2 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
          <div className="PublicFood_support_text PublicFood_block2_text">
            <h2>{t("Mobile_subtitle1")}</h2>
            <p> {t("Mobile_text1")}</p>
          </div>
          <div className="PublicFood_handAndPhone"> </div>
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img  className="Mobile_block2_img" alt="phone" src={rotatedPhone} />
      </div>
      {/* block3 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
        <div className="PublicFood_handAndPhone"> </div>
          <div className="PublicFood_support_text PublicFood_block2_text Mobile_text">
            <h2>{t("Mobile_subtitle2")}</h2>
            <p> {t("Mobile_text2")}</p>
            <p> {t("Mobile_text3")}</p>
          </div>
      
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img  className="PublicFood_block2_img Mobile_img2" alt="phone" src={Phone1} />
      </div>
      {/* block 4 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
      
          <div className="PublicFood_support_text PublicFood_block2_text Mobile_text">
            <h2>{t("Mobile_subtitle3")}</h2>
            <p> {t("Mobile_text4")}</p>
          </div>
          <div className="PublicFood_handAndPhone"> </div>
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img  className="PublicFood_block2_img Mobile_img3" alt="phone" src={Phone2} />
      </div>
      {/* block 5 */}
      <div className="PublicFood_block2">
        <div className="PublicFood_block" id="PublicFood_block">
        <div className="PublicFood_handAndPhone"> </div>
          <div className="PublicFood_support_text PublicFood_block2_text Mobile_text">
            <h2>{t("Mobile_subtitle4")}</h2>
            <p> {t("Mobile_text5")}</p>
          </div>
      
          <img id="PublicFood_BCGimg" alt="bcg" src={bacground} />
        </div>
        <img  className="PublicFood_block2_img Mobile_img2" alt="phone" src={Phone3} />
      </div>
      {/* slide logos of partners */}
      <SlideLine lang={lang}/>

      {/* Blue_block */}
      <div className="PublicFood_Blue_block">
            
            <p>{t("blue_block_text4")}</p>
            <div className="PublicFood_button_wrap">
                <button className="Blue_block_button" onClick={()=>{
                      setIsTrue(true)
                    }}>
                {t("Calculate_the_cost")}
                </button>
            </div>
      </div>
     </div>
    ) 
  }
  
  export default MobileApp