import "./Hardware.css"
import headerPic from "../../img/Hardwere/title1.png";
import headerPic2 from "../../img/Hardwere/title2.png";
import pic1 from "../../img/Hardwere/pic1.png";
import pic2 from "../../img/Hardwere/pic2.png";
import Button from "../Button/Button";
import pic3 from "../../img/products/item6.png";
import { useNavigate } from "react-router-dom";
import {useTranslation} from "react-i18next";
;

function Hardware({ lang, setIsTrue }) {

  const { t } = useTranslation();
  const navigate = useNavigate()
    return (
     <div>
{/* header */}
      <div className="PublicFood_header">
        <div className="PublicFood_header_text">
          <h1>
            {t('Hardware_title')}
          </h1>
        </div>
     
        <div className="PublicFood_header_picture">
          <img id="PublicFood_pic_for_laptop" alt="pic" src={headerPic} />
          <img id="PublicFood_pic_for_mobile" alt="pic" src={headerPic2} />
          <div id="PublicFood_button_component">
            <Button lang={lang} setIsTrue={setIsTrue}/>
          </div>
        </div>
      </div>
{/* block 2 */}
      <div>
        {/* <div className="second_title">
          <h2 className="title_h2">{lang.Hardware_title}</h2>
     
        </div> */}
        <div className="categories_slide_wrapper">
          <div className="categories_flex hardware_categories_flex">
          <div className="equipment_element_inner" onClick={()=>{navigate("CashStations")}}>
            <div className="equipment_element hardware_elem">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t('Hardware_text1')}
                </p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={pic1} />
              </div>
            </div>
           
  </div>
  <div className="equipment_element_inner" onClick={()=>{navigate("Printers")}}>
            <div className="equipment_element hardware_elem">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("Hardware_text3")}
                </p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={pic2} />
              </div>
            </div>
         
  </div>
  <div className="equipment_element_inner" onClick={()=>{navigate("Retail_store_equipment")}}>
            <div className="equipment_element hardware_elem">
              <div className="text_wrapper">
                <p className="equipment_text">
                  {t("Hardware_text4")}
                </p>
              </div>
              <div className="element_img">
                <img className="equipment_img" alt="img" src={pic3} />
              </div>
            </div>
          
  </div>
          </div>
        </div>
      </div>
     </div>
    ) 
  }
  
  export default Hardware