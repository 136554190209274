import React from 'react';
import Cash1 from "../../img/products/cash1.png";
import Cash2 from "../../img/products/Cash2.png";
import Cash3 from "../../img/products/Cash3.png";
import Cash4 from "../../img/products/Cash4.png";
import Cash5 from "../../img/products/Cash5.png";
import {useTranslation} from "react-i18next";

function CashStations({lang}) {
  const { t } = useTranslation();
  const CashStations = [
    {
      img: Cash1,
      name:"Datavan Wonder W-615E intel J6412",

    },
    {
      img: Cash2,
      name:"Wintec AnyPos-300 15.6 intel J6412"
    },
    {
      img: Cash3,
      name:"Wintech AnyPos-600"
    },
    {
      img: Cash4,
      name: "PayTor Libra-150 intel J4125"
    },
    {
      img: Cash5,
      name:  "PayTor Hummer intel J4125"
    }
   
  ]
  return (
   <div  id='printers'>
    <h2> {t("Hardware_text1")}</h2>
      <div className='printers_inner'>
        {
            CashStations.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                       <div className='printer_img'>
                          <img alt='' src={elem.img}/>
                       </div>
                        <h4>{elem.name}</h4>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default CashStations
