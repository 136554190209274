import React from 'react';
import "./products.css";
import printer1 from "../../img/products/iDPRT iT4B.png"
import printer2 from "../../img/products/idprt sp420.png"
import printer3 from "../../img/products/PayTorTRP8004.png"
import printer4 from "../../img/products/IDPRT iE4S.png"
import printer5 from "../../img/products/SewooSLK-TS400.png"
import printer6 from "../../img/products/PayTorTLP38.png"
import printer7 from "../../img/products/printer1.png"
import printer8 from "../../img/products/PayTorTRP8005.png"
import {useTranslation} from "react-i18next";

function Printers({lang}) {
    const { t } = useTranslation();
    const printersInfo = [
        { 
            img: printer1,
            name: "iDPRT iT4B",
        },
        { 
          img: printer2,
          name: "IDPRT SP420",
        },
        { 
          img: printer3,
          name: "PayTor TRP8004",
        },
        { 
          img: printer4,
          name: "PayTor IDPRT iE4S",
        },
        { 
          img: printer5,
          name: " Sewoo SLK-TS 400",
        },
        { 
          img: printer6,
          name: "PayTor TLP38",
        },
        { 
          img: printer7,
          name: " Datavan PR 7120",
        },
        { 
          img: printer8,
          name: "PayTor TRP8005",
        },
      
  
    ]
 
  return (
    <div  id='printers'>
    <h2> {t("Hardware_text3")}</h2>
      <div className='printers_inner'>
        {
            printersInfo.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                       <div className='printer_img'>
                          <img alt='' src={elem.img}/>
                       </div>
                        <h4>{elem.name}</h4>
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default Printers
