
import item1 from "../../img/products/item1.png";
import item2 from "../../img/products/item2.png";
import item3 from "../../img/products/item3.png";
import item4 from "../../img/products/item4.png";
import item5 from "../../img/products/item5.png";
import item6 from "../../img/products/item6.png";
import item7 from "../../img/products/item7.png";
import item8 from "../../img/products/item8.png";
import item9 from "../../img/products/PayTorSS-1128.png";
import item10 from "../../img/products/PayTorRS-1007.png";
import item11 from "../../img/products/PayTorBB-2008.png";
import item12 from "../../img/products/PayTorFL-1008.png";
import item13 from "../../img/products/PayTorGS-1118.png";
import {useTranslation} from "react-i18next";
function RetailStoreEquipment({lang}) {
    const { t } = useTranslation();
    const items = [
        { 
            img: item1,
            type: lang.Keyboard,
            name: " S78A Pos ",
        },
        { 
            img: item2,
            type:  lang.Keyboard,
            name: " PayTor KB-50 POS ",
        },
        { 
            img: item3,
            type: lang.Electromechanical_cash_register,
            name: " FT460 ",
        },
        { 
            img: item4,
            type: lang.Electromechanical_cash_register,
            name: " MK410 Micro Switch ",
        },
        { 
            img: item5,
            type: lang.Electromechanical_cash_register,
            name: " MK350 Micro Switch ",
        },
        { 
            img: item6,
            type: lang.Scanner,
            name: "PayTor FL-1007",
        },
        { 
            img: item7,
            type: lang.Scanner,
            name: "PayTor ES-1007 ",
        },
        { 
            img: item8,
            type: lang.Electromechanical_cash_register,
            name: " MK-460 ",
        },
        // 
        { 
            img: item9,
            type: lang.Scanner,
            name: " PayTor SS-1128 DataMatrix",
        },
        { 
            img: item10,
            type: lang.Scanner,
            name: " PayTor RS-1007 QR DataMatrix ",
        },
        { 
            img: item11,
            type: lang.Scanner,
            name: " PayTor RS-1007PayTor BB-2008 QR Datamatrix ",
        },
        { 
            img: item12,
            type: lang.Scanner,
            name: " PayTor FL-1008 QR DataMatrix",
        },
        { 
            img: item13,
            type: lang.Scanner,
            name: " PayTor GS-1118 QR DataMatrix ",
        },
      
        
    ]
 
  return (
    <div  id='printers'>
    <h2> {t("Hardware_text4")}</h2>
      <div className='printers_inner'>
        {
            items.map((elem,index)=>{
                return(
                    <div className='printer_element' key={index}>
                       <div className='printer_img'>
                          <img alt='' src={elem.img}/>
                       </div>
                       
                        <h4>{elem.type}{elem.name}</h4>
                    
                    </div>
                )
            })
        }
      </div>
    </div>
  )
}

export default RetailStoreEquipment
